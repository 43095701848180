.nonogram {
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;

    .nonogram-grid-container {
        display: flex;
    }

    .nonogram-grid {
        border-spacing: 0;
    }

    .row-hints-container {
        margin-top: auto;
    }

    .cell {
        border: 1px solid black;
        text-align: center;
        vertical-align: middle;

        &.u {
            background-color: #cecece;
        }

        &.f {
            background-color: #76cbe6;
        }

        &.e {
            background-color: #fff;
            box-shadow: inset 0 0 3px #00000080
        }

        &.nonogram-col-thick {
            border-right: 2px solid black;
        }

        &.nonogram-row-thick {
            border-bottom: 2px solid black;
        }

        &.col-complete {
            background-color: #76e6a6;
        }

        &.selected {
            background-color: #76e6a6;
        }
    }

    .nonogram-cell {
        @extend .cell;
        width: 20px;
        height: 20px;
    }

    .row-hints {
        @extend .cell;
        width: auto;
        height: 20px;
        padding: 1px;
        word-spacing: 5px;
    }

    .row-complete {
        background-color: #76e6a6;
    }

    .column-hints {
        @extend .cell;
        width: 20px;
        height: auto;
        white-space: pre-line;
    }
}
