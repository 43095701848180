$primary: #76cbe6;

.landing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: $primary;
}

.landing {
    max-width: 600px;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;

    h1 {
        margin: 0;
        color: $primary;
        font-size: 48px;
    }

    label {
        display: block;
        margin: 20px 0 10px;
        color: $primary;
        font-size: 18px;
        font-weight: bold;
    }

    input[type="number"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        box-sizing: border-box;

        &:focus {
            outline: none;
            border-color: $primary;
        }

        &::placeholder {
            color: #ccc;
        }
    }

    select {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        background-color: #fff;

        &:focus {
            outline: none;
            border-color: $primary;
        }

    }

    input[type="submit"] {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        border: none;
        border-radius: 5px;
        background-color: $primary;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
}
