$primary: #76cbe6;

.room {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    background-color: $primary;

    h1 {
        text-align: center;
    }

    .connected-users {
        text-align: center;
    }
}
