.opponent-grid-wrapper {
    margin: 0 auto 5px auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;

    .opponent-grid {
        border: 1px solid black;
    }

    td {
        width: 5px;
        height: 5px;
    }

    .filled {
        background-color: #76cbe6;
    }

    .empty {
        background-color: #fff;
        box-shadow: inset 0 0 3px #00000080
    }
}
